<template>
  <div class="form">
    <div class="content" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink class="desktop" />
      <div class="content__container content__container_profile">
        <a href="#" class="promo promo_card desktop" @click.prevent="goPayments"><span class="icon-arrow-down"></span>{{ 'payments' | localize }}</a>

        <div class="payment__container">
          <Card class="card__container_right" :cardData="cardData" :key="keyCard" />
          <Operations :cardId="$route.params.id" />
        </div>

        <div class="error__container">
          <p class="error" v-if="error">{{ error | localize }}</p>
        </div>
      </div>
    </div>

    <div class="popup popup_active popup_info" v-if="$store.state.popup.id">
      <div class="popup__overlay popup__overlay_active">
        <div class="popup__center" @click.self="$store.commit('togglePopupText')">
          <div class="popup__window popup__window_blue">
            <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
            <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
            <div class="popup__scrollcontainer ">
              <div class="popup__scrollcontainer_inner">
                <p class="popup__question">{{ "confirm_delete_card" | localize }}</p>
                <div class="popup__double_buttons">
                  <button type="button" class="button button__form" @click="$store.commit('togglePopupText')">{{ "answer_cancel_to_delete_card" | localize }}</button>
                  <button type="button" class="button button__form" @click="deleteCardAction">{{ "answer_delete_card" | localize }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink'
import Operations from '@/components/profile/Operations'
import Card from '@/components/profile/Card'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'CardInfo',
  mixins: [commonMixin],
  components: { Backlink, Operations, Card },
  data: () => ({
    keyCard: 0,
    cardData: {},
    error: null
  }),
  mounted () {
    this.$store.commit('loading', true)
    this.axios
      .get('user_cards/' + this.$route.params.id, { params: { user_id: this.$store.state.user.id || 0 } })
      .then((response) => {
        this.cardData = response.data || {}
        this.cardData.button = 'removeCard'
        this.$store.commit('loading', false)
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
      })
  },
  beforeUpdate () {
    this.keyCard++
  },
  methods: {
    deleteCardAction () {
      this.$store.commit('loading', true)
      this.axios
        .delete('user_cards/' + this.$route.params.id, { params: { user_id: this.$store.state.user.id || 0 } })
        .then(() => {
          this.$store.commit('loading', false)
          this.$store.commit('togglePopupText')
          this.goPayments()
        })
        .catch((error) => {
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$store.commit('loading', false)
        })
    },
    goPayments () {
      this.$router.push({name: 'payments'})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.payment__container {
  position: relative;
  margin-top: 50px;
}
.promo_card {
  .icon-arrow-down {
    margin-right: 10px;

    &::before {
      transform: rotate(180deg);
    }
  }
}

#app.locale_heb {
  .promo_card {
    .icon-arrow-down {
      margin-right: 0;
      margin-left: 10px;

      &::before {
        transform: rotate(0deg);
      }
    }
  }
}

#app.light-theme {
  .promo_card {
    color: $black;
  }
}

@media screen and (max-width: 1024px) {
  .payment__container {
    margin-top: 0;
  }
}
</style>
