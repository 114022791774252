<template>
  <div class="operations">
    <table class="operations__table">
      <tr>
        <th class="clear" :class="{clear_none: operations.length == 0}"></th>
        <th>{{ 'date' | localize }}</th>
        <th class="cell_padding">{{ 'service_type' | localize }}</th>
        <th>{{ 'payment_sum' | localize }}</th>
      </tr>
      <tr class="divider"></tr>
      <tr v-for="(operation, i) in operations" :key="i">
        <td class="clear"><span class="priceIcon"></span></td>
        <td>
          <span v-if="operation.date == today">{{ 'date_operation_today' | localize }}</span>
          <span v-else-if="operation.date == yesterday">{{ 'date_operation_yesterday' | localize }}</span>
          <span v-else>{{ operation.date }}</span>
        </td>
        <td class="cell_padding">{{ operation.name }}</td>
        <td>
          - {{ operation.amount.toLocaleString() }}
          <span class="icon-₪"></span>
        </td>
      </tr>
    </table>
    <div v-if="operations.length == 0" class="info">{{ 'no_operations' | localize }}</div>
  </div>
</template>
<script>
export default {
  name: 'Operations',
  props: { cardId: { type: String, required: true } },
  data () {
    return {
      operations: [],
      today: null,
      yesterday: null
    }
  },
  created () {
    const d = new Date()
    this.today = ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
    this.yesterday = ('0' + (d.getDate() - 1)).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
  },
  mounted () {
    this.$store.commit('loading', true)
    this.axios
      .get('payment', { params: { user_id: this.$store.state.user.id || 0, card_id: this.cardId } })
      .then((response) => {
        this.operations = response.data || []
        this.$store.commit('loading', false)
      })
      .catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/default/vars.scss";
.operations {
  width: 100%;
  padding-right: 500px;
}
.operations__table {
  font-size: 16px;
  line-height: 26px;

  .divider {
    height: 35px;
  }

  th {
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    color: $green;
    border-bottom: 1px solid $white;
    padding-bottom: 30px;

    &.clear {
      border: 0;
      width: 120px;

      &_none {
        display: none;
      }
    }
  }

  .cell_padding {
    padding: 0 20px;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  td {
    padding: 20px 0;
    vertical-align: middle;
  }
}

.priceIcon {
  display: block;
  width: 100px;
  height: 50px;
  background: url("../../assets/images/money_1.svg") center no-repeat;
  background-size: contain;
}

#app.locale_heb {
  .operations {
    padding-right: 0;
    padding-left: 500px;
  }
  .operations__table {
    th {
      text-align: right;
    }

    th:last-child,
    td:last-child {
      text-align: left;
    }
  }
}

#app.light-theme {
  .operations__table {
    th {
      color: $green_dark;
      border-bottom: 1px solid $black;

      &.clear {
        border: 0;
      }
    }
  }

  .priceIcon {
    background: url("../../assets/images/money_1_l.svg") center no-repeat;
    background-size: contain;
  }
}

@media screen and (max-width: 1800px) {
  .operations {
    padding-right: 400px;
  }
  #app.locale_heb {
    .operations {
      padding-left: 400px;
    }
  }
}
@media screen and (max-width: 1700px) {
  .operations {
    padding: 0;
  }
  #app.locale_heb {
    .operations {
      padding: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .operations {
    .info {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .operations__table {
    font-size: 14px;
    line-height: 22px;

    .divider {
      height: 10px;
    }

    .cell_padding {
      padding: 0 10px;
    }

    th {
      font-size: 14px;
      line-height: 22px;
      color: $green;
      padding-bottom: 10px;

      &.clear {
        display: none;
      }
    }

    td {
      padding: 10px 0;

      &:last-child {
        white-space: nowrap
      }

      &.clear {
        display: none;
      }
    }
  }

  .priceIcon {
    display: none;
  }
}
</style>
